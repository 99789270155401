/*
import httpInstance from '../httpInstance';

export const getInventoryAll = async () => {
    let res;

    const endpoint = "http://127.0.0.1:8000/api/transacciones/instalacion/Barcel/";

    try {
        const response = await httpInstance.get(endpoint);
        console.log("Respuesta recibida:", response.data);
        res = response.data;
    } catch (error) { // Explicitly type 'error' as 'any'
        // Manejar cualquier error que ocurra durante la solicitud
        console.error("Error al obtener la respuesta:", error);
        res = error.response;
    }

    return res;
};

*/
// services/inventoryService.js


/*
import httpInstance from '../httpInstance';

export const getInventoryAll = async () => { //127.0.0.1:8000/   be-warehouse-906571168583.us-central1.run.app
    try {

      //https://be-warehouse-906571168583.us-central1.run.app/api/transacciones/instalacion/Barcel/
      //http:/127.0.0.1:8000/api/transacciones/instalacion/Barcel/


      const response = await httpInstance.get('https://back.controldeinventario.info/api/transacciones/instalacion/Barcel/', {withCredentials: true});
  

        console.log("Respuesta recibida:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error al obtener la respuesta:", error);
        throw error;
    }
};

*/
import Cookies from 'js-cookie';
import axios from 'axios';

export const getInventoryAll = async (consulta) => {
  // const accessToken = localStorage.getItem('accessToken'); // Asegúrate de que el token esté almacenado
  // const csrftoken = Cookies.get('csrftoken');
  
  //http://127.0.0.1:8000/api/transacciones/query_sql/', //https://be-warehouse-906571168583.us-central1.run.app/api/transacciones/query_sql/
   try {
    const response = await axios.get('https://back.controldeinventario.info/api/transacciones/instalacion/FAT/', { withCredentials: true });
    console.log("Respuesta recibida:", response.data);
    return response.data;
   } catch(error) {
    console.error("Error al obtener la respuesta:", error);
    throw error;
   }
    

  // const response = await axios.get('https://back.controldeinventario.info/api/transacciones/instalacion/FAT/', 
  //  { consulta },

  //  {
  //    headers: {
  //      'Content-Type': 'application/json',
  //      Accept: 'application/json', // Incluye el token de acceso aquí
  //      'X-CSRFToken': csrftoken, // Incluye el token CSRF aquí
  //      'Authorization': `Bearer ${csrftoken}`,
  //      'Referrer-Policy': 'no-referrer',
  //    },
  //    withCredentials: true,
  //  }
  //);

  // return response.data;
};









/*


import httpInstance from '../httpInstance';

export const getInventoryAll = async () => {
    const accessToken = localStorage.getItem('accessToken'); // Obtén el token de acceso almacenado

    try {
        const response = await httpInstance.get('http://be-warehouse-906571168583.us-central1.run.app/api/transacciones/instalacion/Barcel/', {
            headers: {
                'Authorization': `Bearer ${accessToken}`, // Incluye el token de acceso aquí
            },
        });
        console.log("Respuesta recibida:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error al obtener la respuesta:", error);
        throw error;
    }
};
*/