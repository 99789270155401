import httpInstance from '../httpInstance';

export const handleLogin = async (username, password) => {
    const response = await httpInstance.post('auth/', {
        username,
        password
    }, {withCredentials: true},
    {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Referrer-Policy': 'no-referrer',
        },
    }
    );

    console.log("httpInstance response:", response.data);

    const tokens = await httpInstance.get('cookies/', { withCredentials: true});
  
    // Extrae los tokens de la respuesta
    // const { access, refresh } = response.data;
    // const { csrftoken, sessionid } = tokens.data;

    console.log("Tokens:", tokens.data);
    console.log("Response:", response.data);

    // Set up cookies for the session
    /* This is made on the server side */
    // Cookies.set('sessionid', sessionid);

    // Almacena el token en el almacenamiento local o en el estado
    // localStorage.setItem('accessToken', access);
    // localStorage.setItem('refreshToken', refresh);

    // localStorage.setItem('csrftoken', csrftoken);
    // localStorage.setItem('sessionid', sessionid);

    return response;
};
