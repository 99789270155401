
import React from "react";
import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React components
import MasterCard from "examples/Cards/MasterCard";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import CreditBalance from "./components/CreditBalance";
import QueryForm from "./components/QueryForm";
import HistoricalQueries from "./components/HistoricalQueries";

import { useAuth } from "context/AuthContext";
import { Redirect } from "react-router-dom";

function RTL() {
  const { isAuthenticated } = useAuth();

  // console.log('isAuthenticated Pregunta a base de datos:', isAuthenticated);

  if (!isAuthenticated) {
    return <Redirect to="/iniciar-sesion" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={15} mb={20}>
        <VuiBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7} xl={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                </Grid>
                <Grid item xs={12} md={12} xl={6}>
                </Grid>
                <Grid item xs={12}>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
              </Grid>
          </Grid>
        </VuiBox>
        <VuiBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <QueryForm />
            </Grid>
            <Grid item xs={12} md={5}>
              {/*<HistoricalQueries/>*/}
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RTL;
