import axios from "axios";
import Config from "../config";

const httpInstance = axios.create({
    baseURL: Config.API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
    withCredentials: true,
});

httpInstance.interceptors.request.use(
    async (config) => {
        const newConfig = { ...config };
        return newConfig;
    },
    (error) => {
        return Promise.reject(error);
    }
);

httpInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 403) {
            window.location.href = '/unauthorized';  // Redirige a la página de "No Autorizado"
        }
        return Promise.reject(error);
    }
);

export default httpInstance;
