import { useState, useContext } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { handleLogin } from "../../../services/login/login"
import { useAuth } from "../../../context/AuthContext";

// Images
import bgSignIn from "assets/images/bimbo-image.png";

function SignIn() {
  const { username, setUsername, email, firstName, lastName, signIn} = useAuth(); // Estado para el email
  const [password, setPassword] = useState(""); // Estado para la contraseña
  const [rememberMe, setRememberMe] = useState(true);
  const history = useHistory(); // Hook para redirigir después del login

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const callLoginFunction = async () => {
    try {
      // Llama a la API de inicio de sesión
      await signIn(username, password); 

      // Muestra los datos en la consola
      console.log('Iniciando sesión...');
      console.log('Username:', username);
      console.log('Email:', email);
      console.log('First name:', firstName);
      console.log('Last name:', lastName);

      // Redirige a otra página después del login exitoso
      history.push("/"); // Cambia esto según tu ruta deseada
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      // Maneja errores aquí (ej. muestra un mensaje de error al usuario)
    }
  };

  return (
    <CoverLayout
      title="Iniciar sesión 🔑"
      color="white"
      description="Para iniciar la sesión ingresa tu usuario y contraseña"
      premotto=""
      motto=""
      image={bgSignIn}
    >
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Nombre de usuario
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="email"
              placeholder="Tu nombre de usuario..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={username}
              onChange={(e) => setUsername(e.target.value)} // Actualiza el estado
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Contraseña
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Tu contraseña..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Actualiza el estado
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Recuerdame
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={4} mb={1}>

          {/* Botón para iniciar sesión */}
          {/*
          <Button variant="contained" color="primary" fullWidth onClick={loginPrint}>
            Iniciar sesión
          </Button>
          */}

          <VuiButton color="info" fullWidth onClick={callLoginFunction}> {/* Llama a handleLogin */}
            Iniciar sesión
          </VuiButton>

        </VuiBox>

        {/* Enlace para redirigir a la página de registro */
        /*
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        */
      }
        
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;