import React, { createContext, useContext, useEffect, useState } from 'react';
import { handleLogin } from 'services/login/login';


const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
    // User info
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');

    // Personal info
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    // Important dates
    const [dateJoined, setDateJoined] = useState('');
    const [dateLastLogin, setDateLastLogin] = useState('');

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Provide auth (sing in) function
    const signIn = async (username, password) => {
        console.log('Sign in....');

        // API Call
        try {
            // Call the login function
            const response = await handleLogin(username, password);
            console.log('Response signIn:', response.data);

            // Show user info
            console.log('Username provider:', response.data.username);
            console.log('Email provider:', response.data.email);
            console.log('First name provider:', response.data.first_name);
            console.log('Last name provider:', response.data.last_name);
            console.log('Date joined provider:', response.data.date_joined);
            console.log('Date last login provider:', response.data.last_login);

            // Set user info
            setUsername(response.data.username);
            setEmail(response.data.email);
            setFirstName(response.data.personal_info.first_name);
            setLastName(response.data.personal_info.last_name);
            setDateJoined(response.data.important_dates.date_joined);
            setDateLastLogin(response.data.important_dates.last_login);
            setIsAuthenticated(true);
        }
        catch (error) {
            console.error('Error when signing in:', error);
        }
    }

    return (
        <AuthContext.Provider value={{
            username, setUsername,
            email, setEmail,
            firstName, setFirstName,
            lastName, setLastName,
            dateJoined, setDateJoined,
            dateLastLogin, setDateLastLogin,
            isAuthenticated, setIsAuthenticated,
            signIn
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if(context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }    

    return context;
};
