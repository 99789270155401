import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import Icon from "@mui/material/Icon";
import VuiProgress from "components/VuiProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { getInventoryAll } from "services/inventory/inventoryAll"; // Asegúrate de que la ruta sea correcta

import { Redirect } from "react-router-dom";
import { useAuth } from "context/AuthContext";

function Completion({ value, color }) {
  return (
    <VuiBox display="flex" flexDirection="column" alignItems="flex-start">
      <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
        {value.toFixed(2)}%&nbsp;
      </VuiTypography>
      <VuiBox width="8rem">
        <VuiProgress value={value} color={color} sx={{ background: "#2D2E5F" }} label={false} />
      </VuiBox>
    </VuiBox>
  );
}

function Tables() {
  const [columns, setColumns] = useState([
    { name: "id", align: "left" },
    { name: "instalacion", align: "left" },
    { name: "fecha_hora", align: "left" },
    { name: "numero_lpn", align: "left" },
    { name: "codigo_articulo", align: "left" },
    { name: "descripcion_articulo", align: "left" },
    { name: "cantidad", align: "center" },
    { name: "usuario", align: "left" },
    { name: "transaccion", align: "center" },
    { name: "ubicacion_planeada", align: "left" },
    { name: "ubicacion_real", align: "left" },
    { name: "alera_de_ubicacion", align: "left" },
    { name: "fecha_alerta", align: "left" },
    { name: "pallet_perdido", align: "left" },
    { name: "pallet_encontrado", align: "left" },
    { name: "fecha_resolucion_alerta", align: "left" },



    //{ name: "action", align: "center" },
  ]);

  const [rows, setRows] = useState([]);
  const [totalColumns, setTotalColumns] = useState([
    { name: "producto", align: "left" },
    { name: "total", align: "left" },
    { name: "estatus", align: "left" },
    { name: "porcentaje", align: "center" },
    { name: "accion", align: "center" },
  ]);
  const [totalRows, setTotalRows] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carga
  const { isAuthenticated } = useAuth(); // Estado de autenticación para mostrar o no la página



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Iniciar carga
      try {
      const data = await getInventoryAll();
      
      // Mapear filas para la primera tabla
      const mappedRows = data.map(item => ({
        id: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {item.id}
          </VuiTypography>
        ),
        instalacion: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {item.instalacion}
          </VuiTypography>
        ),
        fecha_hora: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {new Date(item.fecha_hora).toLocaleString()}
          </VuiTypography>
        ),
        ubicacion: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {item.ubicacion_planeada}
          </VuiTypography>
        ),
        transaccion: (
          <VuiBadge
            variant="standard"
            badgeContent={item.transaccion}
            color={item.transaccion === "INGRESO" ? "success" : "error"}
            size="xs"
          />
        ),
        codigo_articulo: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {item.codigo_articulo}
          </VuiTypography>
        ),
        descripcion_articulo: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {item.descripcion_articulo}
          </VuiTypography>
        ),
        cantidad: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {item.cantidad}
          </VuiTypography>
        ),
        usuario: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {item.usuario}
          </VuiTypography>
        ),
        accion: (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Editar
          </VuiTypography>
        ),
      }));
      setRows(mappedRows);

      // Calcular totales para la segunda tabla
      const totals = data.reduce((acc, item) => {
        const { codigo_articulo, descripcion_articulo, cantidad, transaccion } = item;
        if (!acc[codigo_articulo]) {
          acc[codigo_articulo] = {
            descripcion: descripcion_articulo,
            total: 0
          };
        }
        acc[codigo_articulo].total += (transaccion === "INGRESO" ? cantidad : -cantidad);
        return acc;
      }, {});

      // Convertir el objeto totals en un array
      const totalRows = Object.keys(totals).map(codigo_articulo => {
        const { descripcion_articulo, total } = totals[codigo_articulo];
        return {
          producto: (
            <VuiTypography variant="caption" color="white" fontWeight="medium">
              {codigo_articulo} - {descripcion_articulo}
            </VuiTypography>
          ),
          total: (
            <VuiTypography variant="button" color="white" fontWeight="medium">
              {total > 0 ? `${total}` : `$0`}
            </VuiTypography>
          ),
          estatus: (
            <VuiTypography variant="button" color="white" fontWeight="medium">
              {total > 0 ? "En existencia" : "Agotado"}
            </VuiTypography>
          ),
          porcentaje: <Completion value={((total / Object.values(totals).reduce((sum, item) => sum + item.total, 0)) * 100) || 0} color="info" />,
          accion: (
            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
              more_vert
            </Icon>
          ),
        };
      });
      setTotalRows(totalRows);
    } catch (error) {;
      console.error("Error al obtener los datos:", error);
    } finally {
      setLoading(false); // Finalizar carga
    }
  };

    fetchData();
  }, []);


  if(!isAuthenticated) {
    return <Redirect to="/iniciar-sesion" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Movimientos en Almacén
              </VuiTypography>
            </VuiBox>
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </VuiBox>
          </Card>
        </VuiBox>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <VuiTypography variant="lg" color="white">
              Neto de productos en Almacén
            </VuiTypography>
          </VuiBox>
          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={totalColumns} rows={totalRows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;