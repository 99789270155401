import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import ReactMarkdown from 'react-markdown';


import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CircularProgress from '@mui/material/CircularProgress';


// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { querySql } from "services/querySql/querySql";

function QueryForm() {
  const [query, setQuery] = useState("");
  const [querySqlText, setQuerySqlText] = useState("");
  const [result, setResult] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Estado para controlar el mensaje de espera



  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = async () => {
    console.log("Consulta SQL enviada:", query); // Imprime la consulta en consola
    try {
      const data = await querySql(query);
      console.log("Respuesta recibida:", data); // Imprime la respuesta en consola
      setQuerySqlText(data.query_sql); // Asigna el texto de la consulta SQL
      setResult(data.result); // Asigna el resultado de la consulta
      setErrorMessage('');
      setError(data.error); // Asigna el mensaje de error si existe

      setOpenSnackbar(true); // Muestra la snackbar de éxito
    } catch (error) {
      console.error("Error al ejecutar la consulta:", error);
      setErrorMessage("Error de consulta. Verifique fechas, artículo, descripción o valor sensible a espacios y mayúsculas."); // Maneja el error
      setOpenSnackbar(true); // Muestra la snackbar de error
    } finally {
      setLoading(false); // Finaliza el estado de carga
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setErrorMessage("");
  };

  return (
    <Card id="query-form" style={{ maxWidth: '100%', margin: "auto" }}>
      <VuiBox p={3}>
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb={2}>
          Consulta a la base de datos
        </VuiTypography>
        <VuiBox component="form" display="flex" flexDirection="column" gap={4} mt={2} sx={{ width: '100%' }}>
        <TextareaAutosize
            name="query"
            placeholder="Escribe tu pregunta"
            minRows={4}
            value={query}
            onChange={handleChange}
            style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '4px', borderColor: '#ccc', fontFamily: 'inherit', resize: 'none' }}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Ejecutar Consulta
          </Button>
          
          {loading && (
            <VuiTypography variant="body1" color="white" mt={2}>
              Procesando consulta, esto puede tardar hasta 30 segundos...
              <CircularProgress size={24} style={{ marginLeft: '10px' }} />
            </VuiTypography>
          )}
          {!loading && result !== null && (
            <VuiTypography variant="body1" color="white" mt={2}>
              Resultado de la consulta: <br />
              <ReactMarkdown>{result}</ReactMarkdown>
            </VuiTypography>
          )}
          {!loading && error && (
            <VuiTypography variant="body1" color="white" mt={2}>
              {error}
            </VuiTypography>
          )}
          
          


          {querySqlText && (
            <VuiTypography variant="body1" color="white" mt={2}>
              Consulta SQL: <br />
              {querySqlText}
            </VuiTypography>
          )}

        </VuiBox>
      </VuiBox>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={errorMessage ? "error" : "success"}>
          {errorMessage || "Consulta enviada exitosamente"}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default QueryForm;