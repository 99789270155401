import Cookies from 'js-cookie';
import axios from 'axios';

export const querySql = async (consulta) => {
  // const accessToken = localStorage.getItem('accessToken'); // Asegúrate de que el token esté almacenado
  const csrftoken = Cookies.get('csrftoken');
  
  //http://127.0.0.1:8000/api/transacciones/query_sql/', //https://be-warehouse-906571168583.us-central1.run.app/api/transacciones/query_sql/
   


  const response = await axios.post('https://back.controldeinventario.info/api/transacciones/query_sql/', 
    { consulta },

    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json', // Incluye el token de acceso aquí
        'X-CSRFToken': csrftoken, // Incluye el token CSRF aquí
        'Authorization': `Bearer ${csrftoken}`,
        'Referrer-Policy': 'no-referrer',
      },
      withCredentials: true,
    }
  );

  return response.data;
};